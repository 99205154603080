@import 'variables';

md-menu-content,
md-select-menu {

    background-color: white;
}

.md-open-menu-container {

    border-radius: 5px;

    md-menu-content {

        md-menu-item:hover {
            background-color: map-get($colors, background);
            transition: background-color 0.1s ease-in;
            transition-delay: 0ms;
        }

        md-menu-divider {
            background-color: map-get($colors, lighter);
        }

        &.menu-full-height {
            max-height: 100vh;
        }
    }
}

.md-open-menu-container.md-active>md-menu-content>* {
    transition: all 0.15s ease;
    transition-delay: 0ms;
}