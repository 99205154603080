@import './variables';

md-dialog {
    min-width: 350px;
    background: $modal-background;
    border-radius: $modal-border-radius;
    box-shadow: $modal-box-shadow;

    md-toolbar {
        min-height: $modal-toolbar-min-height;
        background: $modal-toolbar-background-color;

        .md-toolbar-tools {
            padding: $modal-toolbar-padding;
            color: $modal-toolbar-color !important;
        }
    }

    md-dialog-content {
        padding: $modal-padding;
    }

    md-dialog-actions {
        padding: $modal-toolbar-padding;
        border-top: 1px solid map-get($colors, lighter);
    }
}

.modal-content {
    border-radius: $modal-border-radius;
    overflow: hidden;
}

.modal-header {
    display: flex;
    align-items: center;
    min-height: $modal-toolbar-min-height;
    padding: $modal-toolbar-padding;
    background: $modal-toolbar-background-color;
    color: white;
}

.modal-title {
    @extend .display-3;
    
    margin: none;
}

.modal-body {
    padding: $modal-padding;
}

.modal-footer {
    padding: $modal-footer-padding;
    border-top: 1px solid map-get($colors, lighter);
}