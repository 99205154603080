/* Customizations to the designer theme should be defined here */

/* Button */
/*
 * font-size overrides because the angular-material style has a font-size: 100% and because it isn't in a layer it takes
 precentence over the p-button style
 */
.p-button {
    font-weight: 600;
    font-size: 12px !important;
    height: 32px;
    white-space: nowrap;

    i {
       font-size: 12px !important;
    }

    svg-icon {
        @apply h-4 w-4;
    }
}

/* This handles spacing regardless of which side the icon is on and whether or not there is content */
.p-button:not(.p-inputnumber-button) {
    display: grid;
    grid-auto-flow: column;
    gap: 6px;
}

.p-button.h-full {
    height: 100%;
}

.p-button.p-button-sm {
    font-size: 12px !important;
    height: 24px;
    padding: 0 10px;

    i {
        font-size: 12px !important;
    }

    svg-icon {
        @apply h-[14px] w-[14px];
    }
}

.p-button.p-button-lg {
    font-size: 14px !important;
    height: 44px;

    i {
        font-size: 18px !important;
    }

    svg-icon {
        @apply h-5 w-5;
    }
}

.p-button.p-button-outlined {
    background: #ffffff;
}
.p-button.p-button-outlined:not(:disabled):hover {
    background: #f2f8fe;
}
.p-button.p-button-outlined:not(:disabled):active {
    background: #dfebff;
}

.p-button-loading {
    font-size: 0px !important;
    gap: 0 !important;

    i,
    svg-icon {
        display: none;
    }
}

p-button.block .p-button {
    width: 100%;
    text-align: center;
    display: block;
}

p-button.w-full .p-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .p-button-label {
        flex: none;
    }
}

p-button.bg-white {
    background: transparent;

    button {
        background-color: #fff;
    }
}

.p-button.p-button-text {
    padding-left: 12px;
    padding-right: 12px;
}

.p-button.p-disabled {
    opacity: 0.3;
}

.p-button.icon-only {
    padding: 0 6px;
}

/* Select button */
.p-selectbutton {
    border-radius: $borderRadius;
    @apply h-8 w-max p-1 flex items-center gap-1 flex-nowrap border border-grey-30;

    .p-button {
        border-radius: $borderRadius;
        font-size: 10px !important;
        @apply h-6 px-3 uppercase;
    }
}
// full width stretch the inner button to fit
.p-selectbutton.w-full {
    width: 100%;

    .p-button {
        width: 100%;
    }
}

/* Number input */
.p-inputnumber-input {
    width: inherit; // p-inputnumber wraps a p-input text element but the style classes aren't inherited so setting the width doesn't work unless we add this.
}

/* Inputswitch */
p-inputswitch {
    @apply h-6;
}

.p-inputswitch {
    @apply rounded-full border border-grey-50;

    .p-inputswitch-slider {
        &:before {
            left: 1px;
        }
    }
}

.p-inputswitch.p-inputswitch-checked {
    @apply border-primary;

    .p-inputswitch-slider {
        &:before {
            left: 3px;
        }
    }
}

/* Input */
/*
 * setting min height here (basically resetting the height) as $input-min-height: 40px; in admin > src > theme > _variables.scss is interfering with the p-input height, particularly in modals for some reason.
 */
input.p-inputtext {
    min-height: min-content;
}

/* Panel */
.p-panel {
    box-shadow: 0px 2px 2px 0px #0000001a;
    border-radius: 8px;

    .p-panel-header {
        border-bottom: 1px solid #e7e8ea;
    }

    .p-panel-footer {
        border-top: 1px solid #e7e8ea;
    }

    i.fas {
        @apply text-grey-30 text-lg;
    }

    .p-panel-title {
        font-size: 18px;
    }
}

// Allow panels and overlay panels to have no padding
p-panel.p-0 {
    .p-panel-content {
        padding: 0;
        overflow: hidden;
    }
}
p-dialog.p-0 {
    .p-dialog-content {
        padding: 0;
        overflow: hidden;
    }
}

p-dialog.overscroll {
    .p-dialog-content {
        overflow: auto;
    }
}

.p-overlaypanel.p-0 {
    .p-overlaypanel-content {
        padding: 0;
        overflow: hidden;
    }
}

/* Dropdown */
.p-dropdown {
    @apply flex items-center;
}

.p-dropdown-label {
    font-size: 0.875rem;
}

.p-dropdown timesicon {
    // hide the default clear icon
    // svg {
    //     display: none;
    // }
    // // replace with our icon
    // &::after {
    //     content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath fill='%237D838B' d='M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z'/%3E%3C/svg%3E");
    //     height: 8px;
    //     width: 8px;
    //     // margin-top: 9px; // hack for positioning
    // }
}
.p-dropdown-trigger {
    // hide the default icon
    chevrondownicon {
        display: none;
    }
    // replace with our icon
    &::after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%237D838B' d='M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z'/%3E%3C/svg%3E");
        height: 7px;
        width: 10px;
        margin-top: -17px; // hack for positioning
    }
}

.p-dropdown-panel {
    max-width: 800px;

    .p-dropdown-item {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

/* Menu */
.p-menuitem {
    margin: 10px 0px;
}

.p-menuitem-link {
    @apply flex items-center justify-between;
}

/* Avatar */
.p-avatar-text {
    color: #6ca9ff;
    font-weight: 600;
}

.p-dialog-header-icon {
    color: #fff;
}

/* Tab View */
.p-tabview {
    box-shadow: 0px 2px 2px 0px #0000001a;
    border-radius: 8px;
    overflow: hidden;
}
// if we want the tab view to be the height of it's container
.p-tabview.h-full {
    height: 100%;

    .p-tabview-panels,
    .p-tabview-panel {
        height: 100%;
    }
}
// no padding on content (ie for a map)
.p-tabview.p-0 {
    .p-tabview-panels {
        padding: 0px;
    }
}

.p-tabview-nav {
    height: 64px;
    padding: 4px;
    display: flex;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    li {
        width: 100%;
        height: 100%;
    }
}

.p-tabview-nav-link {
    height: 100%;
}

.p-tabview-title {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
}

/* Card */
// if we want the card to be the height of its container
.p-card.h-full {
    .p-card-body,
    .p-card-content {
        height: 100%;
    }
}
// if we want to remove padding from the card
.p-card.p-0 {
    .p-card-body,
    .p-card-content {
        padding: 0px;
    }
}

.map-wrapper {
    .p-button.p-button-outlined {
        background: #ffffff;
    }
}

.p-dialog,
.p-panel {
    form {
        label {
            font-weight: 400;
            line-height: 1rem;
            font-size: 14px;
        }
    }
}

/* Table */
// To remove padding from the header/caption so we can add a border between rows
.p-datatable.p-0 > .p-datatable-header {
    padding: 0px;
}

.table-item {
    @apply h-20 flex items-center border-b border-grey-30;
    padding: 10px;

    &__inner {
        @apply h-full w-full rounded-lg flex items-center bg-white text-sm text-text-primary;
        padding: 10px;
    }

    &__primary-text {
        @apply text-primary font-semibold;
    }

    &__buttons {
        @apply flex items-center justify-end gap-[10px];

        i[pReorderableRowHandle] {
            @apply h-10 w-10 flex items-center justify-center rounded-lg text-lg text-grey-30;

            &:hover {
                @apply text-primary-200;
            }
        }
    }

    &__button {
        @apply h-10 w-10 flex items-center justify-center rounded-lg text-lg text-grey-50;

        &:hover {
            @apply bg-primary-50;
        }

        i {
            @apply w-6;
        }
    }

    &--thin {
        height: 60px;
        padding: 5px;

        .table-item__inner {
            padding: 7px 10px;
        }

        .table-item__buttons {
            i[pReorderableRowHandle] {
                @apply h-6 w-6 text-[20px];
            }
        }

        .table-item__button {
            @apply h-[30px] w-[30px];

            i {
                @apply w-5;
            }
        }
    }
}

.table-item:hover {
    @apply cursor-pointer;

    .table-item__inner {
        @apply bg-primary-10;
    }
}

.table-item.selected {
    .table-item__inner {
        @apply bg-primary-50;
    }
}

.table-item.selected {
    .table-item__button:hover {
        @apply bg-primary-100;
    }
}

.p-paginator {
    @apply border-t border-grey-30;

    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
        @apply text-primary hover:bg-primary-50;
    }

    .p-paginator-page {
        @apply text-primary font-semibold hover:bg-primary-50;

        &.p-highlight {
            @apply text-white;
        }
    }
}

.p-datatable-loading-overlay.p-component-overlay {
    background-color: rgba(255, 255, 255, 0.6);

    .p-datatable-loading-icon {
        @apply text-primary;
    }
}

.p-datatable-tbody {
    > tr.table-item,
    > tr.coreo-list-content-item {
        &.p-datatable-dragpoint-top {
            box-shadow: inset 0 2px 0 0 $highlightBg;
        }

        &.p-datatable-dragpoint-bottom {
            box-shadow: inset 0 -2px 0 0 $highlightBg;
        }
    }
}

/* Dialog */
.p-dialog .p-dialog-header .p-dialog-header-icon:hover {
    color: $primaryColor;
}

.p-dialog.hide-close .p-dialog-header button.p-dialog-header-close {
    @apply hidden;
}

.p-dialog.footer-block {
    .p-dialog-footer {
        display: block;
    }
}

.p-dialog {
    .p-dialog-header {
        .p-dialog-header-icon {
            color: $primaryTextColor;
            padding: 0;
        }
    }

    &.p-confirm-dialog .p-confirm-dialog-message {
        margin-left: 0px;
    }

    .p-dialog-footer {
        @apply flex items-center justify-end gap-2;

        button {
            margin: 0px;
        }
    }

    .p-dialog-footer.display-block {
        @apply block;
    }
}

.p-dialog {
    min-width: 400px;
}

p-toast.app-toast {
    .p-toast {
        width: auto;

        &-message {
            border-radius: 6px;

            &-content {
                padding: 12px 16px;
                align-items: center;
                gap: 1rem;
            }

            &-success {
                background: #15cb64;
            }

            &-error {
                background: #fa4d56;
            }

            .wrapper {
                display: flex;
                align-items: center;
                gap: 10px;

                img {
                    width: 24px;
                    height: 24px;
                }

                p {
                    color: #fff;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }
}

.p-checkbox .p-checkbox-box.p-disabled {
    background-color: #e9edf1;
}

p-accordion.upgrade-plan {
    width: 100%;
    border: 1px solid #D3DBE3;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;

    .p-accordion-header-link {
        padding: 0;
        border-radius: 0 !important;
    }
    .p-accordion-toggle-icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: #0069DF;
    }
    .p-accordion-content {
        padding: 0;
    }
}

.drop-area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border: 1px dashed #878f97;
    transition: background-color 0.3s;

    &.p-draggable-enter {
        background-color: #f2f8fe;
    }
}

[pDraggable] {
    cursor: move;
}

p-colorPicker {
    .p-colorpicker-preview {
        width: 42px;
        height: 42px;
        border: none;
        border-radius: 4px 0 0 4px;
        border-right: 1px solid #d3dbe3;
        box-shadow: none;
    }
}
.p-colorpicker.circle {
    .p-colorpicker-preview {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        border-width: 0px;
        padding: 0;
    }
}

p-orderList.map-order-list {
    touch-action: none;
    
    .p-orderlist-controls {
        display: none;
    }
    .p-orderlist-list-container {
        background: transparent;
        border: none;

        .p-orderlist-list {
            padding: 0;
            min-height: 0;
        }

        .p-orderlist-item {
            margin: 4px 0;
            padding: 0;
        }

        li.p-focus,
        li:hover,
        li.p-highlight {
            color: #444;
            background: inherit;
        }

    }
}

/* Toast */
// Re-position the close button
.p-toast .p-toast-message {
    position: relative;
}
.p-toast .p-toast-message .p-toast-icon-close {
    position: absolute;
    top: 9px;
    right: 8px;
}
// For error toast hide the default timescircleicon icon and replace with ours
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon {
    height: 35px;
    width: 35px;
    // hide the default icon
    timescircleicon {
        display: none;
    }
    // replace with our icon
    &::after {
        display: block;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 35'%3E%3Cpath fill='white' d='M19.647 3.42655C18.6761 1.77448 16.3307 1.77448 15.3597 3.42655L0.360119 28.9474C-0.63964 30.6484 0.560609 32.8125 2.50379 32.8125H32.503C34.4461 32.8125 35.6464 30.6484 34.6466 28.9474L19.647 3.42655ZM17.5 10.9375C18.6712 10.9375 19.5864 11.9487 19.4698 13.1141L18.7026 20.7867C18.6408 21.4045 18.1209 21.875 17.5 21.875C16.8791 21.875 16.3592 21.4045 16.2974 20.7867L15.5302 13.1141C15.4136 11.9487 16.3288 10.9375 17.5 10.9375ZM17.5034 24.0625C18.7115 24.0625 19.6909 25.0419 19.6909 26.25C19.6909 27.4581 18.7115 28.4375 17.5034 28.4375C16.2952 28.4375 15.3159 27.4581 15.3159 26.25C15.3159 25.0419 16.2952 24.0625 17.5034 24.0625Z'/%3E%3C/svg%3E");
        height: 35px;
        width: 35px;
    }
} 

/* Overlay panel */
.p-overlaypanel {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    // Hide triangle
    &:after {
        border: solid transparent;
        border-color: transparent;
        border-bottom-color: transparent;
    }

    &:before {
        border: solid transparent;

        @if (nth($overlayContentBorder, 2) == 'none') {
            border-color: transparent;
            border-bottom-color: transparent;
        }
        @else {
            border-color: transparent;
            border-bottom-color: transparent;
        }
    }

    &.p-overlaypanel-flipped {
        &:after {
            border-top-color: transparent
        }

        &:before {
            @if (nth($overlayContentBorder, 2) == 'none') {
                border-top-color: transparent
            }
            @else {
                border-top-color: transparent;
            }
        }
    }
}

/* Tooltip */
.p-tooltip {
    max-width: 18rem;
}