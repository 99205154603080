.home-item-container {
    @apply w-full h-full text-xl p-3 gap-4 flex items-center cursor-pointer rounded-lg;

    &:hover {
        @apply bg-[#F2F8FE];
    }

    button {
        @apply flex w-10 h-10 items-center justify-center rounded-lg hover:bg-[#DFEBFF] opacity-0 transition-opacity;

        svg-icon,
        i.fas {
            @apply text-[#878F97];

            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    .home-item-buttons-container {
        @apply block transition-opacity w-[360px];
    }
    .home-item-buttons {
        @apply flex items-center gap-6 justify-end;
    }

    .home-item-buttons.can-select button.home-item-select {
        @apply opacity-100;
    }

    &:hover button {
        @apply opacity-100;
    }
}

.home-sort-container {
    @apply w-full flex items-center py-2;

    button {
        @apply flex items-center gap-1 cursor-pointer;
    }

    button.active {
        @apply font-semibold;

        i {
            @apply visible;
        }
    }

    i {
        @apply transition-transform invisible;
    }

    &.reverse i {
        @apply transform rotate-180;
    }
}

:host(.selected) {
    .home-item-container {
        @apply bg-[#DFEBFF];
    }

    button {
        @apply hover:bg-[#BCD5FF];
    }

    button {
        @apply opacity-100;
    }
}

:host(.dropping) {
    .home-item-container {
        @apply bg-[#DFEBFF];
    }
}

.p-breadcrumb {
    padding: 0;
}

.p-breadcrumb ol {
    position: relative;

    li {
        @apply xl:max-w-[35%] lg:max-w-[25%] max-w-[15%];

        span {
            color: #878f97;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        span:hover {
            color: #0069df;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }

    li:not([id]) {
        pointer-events: none;
    }

    li:last-child a span {
        color: #444444;
        font-weight: 600;
        pointer-events: none;
    }

    chevronrighticon {
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #878f97;
    }
}

p-breadcrumb.move-modal li {
    max-width: 25%;
}
