.coreo-list {
    @apply block p-0;

    &-header {
        @apply w-full flex items-center justify-between;

        h2 {
            @apply font-bold text-lg text-grey-100;

            i {
                @apply text-2xl text-grey-30 mr-2;
            }
        }

        button, p-button {
            @apply p-button-lg;

            i {
                @apply mr-3;
            }
        }

    }

    &-content {
        &-item {
            @apply relative w-full cursor-pointer text-primary-lighten-50 border-b border-grey-30;

            &-body {
                @apply flex justify-between items-center p-2.5 rounded-3xl bg-clip-content
                hover:text-grey-50 hover:bg-[#F2F8FE]
                active:text-grey-50 active:bg-[#DFEBFF];
            }

            &-body * {
                @apply px-3 py-6 inline-block;
            }
        }
    }

    &-no-content {
        @apply flex-grow flex flex-col items-center justify-center gap-5;

        i {
            @apply text-grey-105 text-7xl;
        }

        h1 {
            @apply text-base font-semibold text-grey-120 text-center;
        }

        p {
            @apply text-sm font-normal text-grey-50 text-center w-[460px];
        }

        button, p-button {
            @apply p-button-lg mt-5;

            i {
                @apply mr-3;
            }
        }
    }
}