.app-get-started {
  max-width: 330px;
  text-align: center;
  margin: auto;
}

.app-color-sample {
  border-radius: 10px;
  padding: 1em;
}

.sample-text {
  text-align: center;
}

.sample-title {
  font-size: 1.3em;
  font-weight: bold;
}

.sample-dim {
  opacity: 0.6;
}

.row-spaced {
  margin-top: 20px;
}

textarea.css {
  min-height: 300px;
  font-family: monospace;
  font-size: 0.9em;
}

.inline-input {
  display: inline-block;
  width: 120px;
  text-align: right;
}

.nav-tabs-custom a {
  cursor: pointer;
}

.color-picker-wrapper .input-group .input-group-addon {
  padding-left: 16px;
  padding-right: 16px;
}

.app-icon {
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;

  img {
      max-width: 100%;
      max-height: 100px;
  }
}

.preview-app {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 15px;

  >div {
      padding-bottom: 15px;
  }

  h3 {
      font-size: 1.1em;
      font-weight: bold;
  }

  h4 {
      font-size: 1em;
      font-weight: bold;
      color: #555;
  }
}

.apple-status-default {
  background: #EEE;
  color: #111;
}

.apple-status-black {
  background: #000;
  color: #FFF;
}

.preview-app-icon {
  height: 110px;
  width: 110px;
  background: #222;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
      height: 64px;
      width: 64px;
  }

  .preview-app-name {
      max-width: 80px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      margin-top: 5px;
      color: #FFF;
      font-size: 12px;
  }
}

.preview-app-icon-ios {
  img {
      border-radius: 14px;
      background: #000;
  }
}

.preview-splash-screen {
  width: 180px;
  height: 320px;
  position: relative;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .preview-status {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      height: 12px; // background: rgba(0,0,0, 0.2);
  }

  img {
      height: 92px;
      width: 92px;
  }
}

ul.form-list.app-list {
  max-width: 800px;
  display: block;
  margin: 0 auto;

  .form-list-survey {
      margin: 8px 0;

      i {
          visibility: hidden;
          margin-left: 4px;
      }
  }

  .form-option-buttons {
      flex-direction: row;

      &> :first-child {
          margin-right: 8px;
      }
  }
}

.subdomain-input {
  border: 0;
  border-bottom: 1px solid #ccc;
  text-align: center;
  padding: 4px 0;
  margin: 0 9px;
}

.app-link {
  i {
      margin-left: 4px;
  }
}

.subdomain-save-text {
  font-size: 0.8em;
  margin: 4px 0;
}

.app-pages-list.form-list {
  max-width: 800px;
  margin: 0 auto;

  .form-list-depth-0 {
      margin-top: 0;
  }

  .form-list-depth-1 {
      .form-list-info {
          width: calc(50% - 70px);
      }

      margin-left: 30px;
  }

  .form-list-depth-2 {
      margin-left: 60px;

      .form-list-info {
          width: calc(50% - 70px);
      }
  }
}

.trash-can-right {
  margin-left: auto;
}

/* This will disable the panel animation */
md-tabs [role="tabpanel"] {
  transition: none;
}

/* This will disable the `ink-bar` animation (border-bottom of selected tab) */
/*
md-tabs md-ink-bar {
transition: none;
}
*/    

.md-input { 
  height: 40px;
  margin: 0px;
  border: 1px solid #beccdd;
  border-radius: 5px;
}
