@import "variables";
@import "buttons";
@import "tabulator-tables/src/scss/themes/tabulator_simple.scss";

.tabulator {
    &-col-title {
        @apply text-grey-80 text-xs leading-3 font-semibold tracking-tight;
    }

    &-header-filter {
        input {
            background-color: transparent !important;
        }

        input::placeholder {
            color: transparent;
        }
    }

    .tabulator-header {
        border-bottom: $table-cell-border;

        .tabulator-col {
            padding: $table-header-cell-padding;
            border-right: none;
            color: map-get($colors, dark);
            outline: $table-cell-border;
            text-transform: uppercase;

            .tabulator-col-content {
                padding: 0px;
            }

            .tabulator-header-filter {
                margin-top: 5px;

                input {
                    padding: 5px;
                    color: map-get($colors, dark);
                    background-color: map-get($colors, background);
                    border: 1px solid map-get($colors, lighter);
                    border-radius: $input-border-radius;
                    font-weight: 400;
                }

                input[type="search"]::-webkit-search-cancel-button {
                    display: block;
                    cursor: pointer;
                    -webkit-appearance: auto;
                }
            }
        }

        .tabulator-col.tabulator-sortable:hover {
            background-color: white;

            input {
                background-color: white;
            }
        }
    }

    .tabulator-row {
        @apply border-grey-15;
        @apply border-t;
        @apply text-grey-80;
        border-top-color: transparent;

        .tabulator-cell {
            @apply leading-5;
            padding: $table-row-cell-padding;
            border-right: $table-cell-border;
        }

        .tabulator-cell:last-child {
            border-right: none;
        }
    }

    // .tabulator-row.tabulator-row-even {
    //     background-color: $table-row-even-background-color;
    // }

    .tabulator-row.tabulator-selectable:hover {
        background-color: $table-cell-hover-background-color;

        input {
            background-color: white;
        }
    }

    .tabulator-footer {
        padding: 15px;
        border-top: $table-cell-border;

        .tabulator-paginator {
            display: flex;
            align-items: center;
            width: 100%;

            label {
                margin: auto 0;
                color: map-get($colors, medium);
                font-size: 12px;
            }

            .tabulator-page-size {
                margin: 0 auto 0 10px;
                height: $input-min-height;
                color: map-get($colors, dark);
                font-weight: 400;
                background-color: white;
                border: 1px solid $input-border-color;
                border-radius: $input-border-radius;
            }

            .tabulator-page,
            .tabulator-page:not(.disabled):hover {
                @extend .button, .coreo-button, .clear;

                height: $button-small-height;
                padding: $table-pagination-button-padding;
                border: none;
                border-radius: $table-pagination-button-border-radius;
            }

            .tabulator-pages {
                .tabulator-page,
                .tabulator-page:hover {
                    background-color: map-get($colors, lighter);
                    color: map-get($colors, dark);
                }

                .tabulator-page.active,
                .tabulator-page.active:hover {
                    color: white;
                    background-color: map-get($colors, primary);
                    box-shadow: $button-box-shadow;
                }
            }
        }
    }

    .tabulator-row.tabulator-selected {
        @apply bg-primary-lighten-50;
        @apply border-primary-lighten-25;
        @apply border-b;
        @apply border-t;
    }
}
