.project-users {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 24rem 1fr;
    grid-template-rows: #{"max(24rem, 50vh) 1fr"};
    padding: 48px;

    &--table {
        grid-column: 1 / span 2;
        grid-row: 1;
    }

    md-card-content {
        flex-grow: 1;
    }

    .coreo-button.small {
        max-height: 32px;
    }
}

.project-users-add-user {
    md-autocomplete {
        width: 100%;
        color: #0e0f11;
    }

    md-radio-button {
        font-size: 0.75rem;

        .md-container {
            top: 0;
            transform: none;
        }

        h4 {
            margin-top: 0;
            font-weight: 700;
            font-size: 0.75rem;
            color: #0e0f11;
            letter-spacing: 0.025em;
            text-transform: uppercase;
        }

        p {
            color: #878f97;
        }
    }

    .md-on {
        background-color: #0069df;
    }
}

.tooltip-wrapper [disabled] {
    pointer-events: none;
  }