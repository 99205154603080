@import '../../theme/variables';

media-manager-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    width:100%;

    .media-manager-item-img {
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width:100%;
    }

    .media-manager-item-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .media-manager-item-content {
        flex-direction: column;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        a {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
        }
    }

    .media-manager-item-container-audio {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        audio {
            width: 100%;
        }
    }
}