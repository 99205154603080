project-map,
project-records-map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .project-map {
        width: 100%;
        height: 100%;
        transition: opacity 0.3s;
        background-color: #888;
        opacity: 1.0;

        &.loading {
            opacity: 0;
        }
    }
}

#project_map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

project-map,
project-records-map,
.project-map-config {
    width: 100%;
    height: 100%;

    h3 {
        font-size: 14px;
        font-weight: bold;
        border-bottom: 1px solid#333f46;
        padding-bottom: 12px;
        margin-bottom: 0;
        padding-left: 8px;
        margin-top: 16px;
    }

    h4 {
        font-size: 12px;
        font-weight: bold;
    }

    .control-label {
        color: white;
    }

    .form-control {
        border-radius: 4px;
        // background: #333f46;
        // color: white;
        height: 28px;
    }

    .btn-sm {
        padding: 2px 8px;
    }
}

.form-list button {
    display: block;
    margin: 0 auto;
}

.project-maps-container {
    md-select {
        position: absolute;
        right: 8px;
        top: 8px;
        background: white;
        border-radius: 4px;
        width: 120px;
        z-index: 999999;
        min-width: 140px;
        padding-left: 8px;
    }
}