$backgroundColor: #2c3439;
$foregroundColor: white;

.project-map-config-container {
    width: 100%;
    height: 100%;
}

.project-map-config-toolbar {
    flex: 0 0 32px;
    background: white;

    p {
        margin: 0;
    }
}

.project-map-config {


    mapbox-style-editor {
        width: 600px;
    }

    // .project-map-config-layer {
    //     color: $foregroundColor;
    // }

    .project-map-config-layer-inner {
        overflow-y: auto;
        height: 100%;
    }

    .project-map-config-layer-inner::-webkit-scrollbar,
    md-content::-webkit-scrollbar {
        width: 0 !important;
        display: none;
    }

    .md-button[small] {
        height: 32px;
        margin: 0;
        max-height: 20px;
        overflow: hidden;
        min-height: 20px;
        line-height: 20px;
        font-size: 12px;
    }

    $layersWidth: 180px;

    md-tabs {
        // height: 100%;
        width: 100%;

        md-content {
            height: 100%;
            // background: $backgroundColor;
            // color: $foregroundColor;
            padding: 8px;
        }

        .md-tab {
            font-size: 12px;
            padding: 0;
        }
    }

    input {
        border-color: transparent;
    }

    md-select {
        margin: 0 0 0 12px;
        width: 100%;
    }

    md-select:not([disabled]):focus .md-select-value {
        border-bottom-color: rgba(0, 0, 0, 0.12);
        color: $foregroundColor;
    }

    $formRowHeight: 24px;

    .layout-form-row {
        height: $formRowHeight;
        margin: 8px;

        label {
            line-height: 32px;
            margin: 0;
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &.layout-form-row-textarea {
            height: 120px;

            textarea {
                height: auto;
            }
        }
    }

    .project-map-config-map-container {
        position: relative;
    }

    .project-map-config-layers {
        flex: 0 0 200px;
        // background: $backgroundColor;
        z-index: 9999;
        // color: $foregroundColor;
        overflow-y: auto;

        .badge {
            vertical-align: text-bottom;
        }

        md-tabs {
            border-radius: 0;
            margin: 0;
        }
    }

    .project-map-config-layer-list {
        list-style: none;
        padding-left: 0px;
        padding-right: 12px;

        li {
            cursor: pointer;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 28px;
            line-height: 12px;
            font-size: 12px;
            opacity: 0.6;

            &.active {
                opacity: 1.0;
            }
        }
    }

    .project-map-config-layer {
        // background: $backgroundColor;
        border-left: 1px solid #333f46;
        flex: 0 0 340px;

        img {
            max-height: 64px;
            width: auto;
            margin: 0 auto;
            display: block;
        }
    }

    .project-map-config-image-preview {
        width: 32px;
        height: 32px;
        border: 2px solid #FFF;
        border-radius: 50%;
        object-fit: cover;
        filter: blur(2px);
        background-color: #00FFFF;
    }

    md-select .md-select-icon {
        color: $foregroundColor;
    }

    md-switch {
        margin: 0;
        margin-left: auto;
    }
}