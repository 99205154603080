@layer tailwind-base, primeng;

@import "tailwindcss/base" layer(tailwind-base);
@tailwind components;
@tailwind utilities;

@import "./app/theme/styles/coreo-styles.scss";
@import "./app/theme/coreo/theme.scss";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

@import "./admin/boundaries/admin-boundaries.scss";
@import "./main/app-header/app-header.directive.scss";
@import "./main/breadcrumbs/breadcrumbs.component.scss";
@import "./main/collection-select/collection-select.component.scss";
@import "./main/help-tooltip/help-tooltip.directive.scss";
@import "./main/list/list.directive.scss";
@import "./main/min-screen-size-block/min-screen-size-block.component.scss";
@import "./main/searchable-list/searchable-list.component.scss";
@import "./main/sidebar-content/sidebar-content.component.scss";
@import "./main/tutorial/tutorial.scss";
@import "./main/main.scss";

@import "./auth/accountverification/account-verification.scss";
@import "./auth/passwordupdate/passwordupdate.scss";

@import "./admin/mailing/admin-mailing.scss";

@import "./app/features/home/home.styles.scss";

@import "./organisations/invitation/organisation-invitation.scss";
@import "./organisations/members/organisation-members.scss";
@import "./organisations/settings/organisation-settings.scss";

@import "./projects/dashboard/project-dashboard.scss";
@import "./projects/maps/base-selector/project-map-base-selector.component.scss";
@import "./projects/maps/map-data-layer-editor/map-data-layer-editor.component.scss";
@import "./projects/maps/mapbox-style-editor/mapbox-prop-editor.component.scss";
@import "./projects/maps/mapbox-style-editor/mapbox-style-editor.component.scss";
@import "./projects/maps/project-map-config.scss";
@import "./projects/maps/project-map-editor.scss";
@import "./projects/maps/project-maps.scss";
@import "./projects/media/media-manager-item.component.scss";
@import "./projects/media/media-manager.component.scss";
@import "./projects/pages/project-page-wysiwyg.scss";
@import "./projects/pages/project-page.scss";
@import "./projects/project-media-item.component.scss";
@import "./projects/projects.scss";
@import "./projects/record/record-state/project-record-state.component.scss";
@import "./projects/records/project-records-attribute-filter/project-records-attribute-filter.scss";
@import "./projects/records/project-records-import/project-records-import.scss";
@import "./projects/records/project-records-map/project-records-map.scss";
@import "./projects/records/project-records-table/project-records-table.component.scss";
@import "./projects/records/project-records-toolbar/project-records-toolbar.component.scss";
@import "./projects/records/project-records.scss";
@import "./projects/settings/project-record-status-settings.scss";
@import "./projects/styling/color-selector/color-selector.component.scss";
@import "./projects/styling/project-styling.scss";
@import "./projects/ui/project-collection-select/project-collection-select.component.scss";
@import "./projects/ui/project-sidebar/project-sidebar.component.scss";
@import "./projects/users/project-users.scss";

@import "./legal/legal.scss";
@import "./legal/privacy/privacy.scss";
@import "./legal/terms/terms.scss";

.content-wrapper {
    background-color: #ecf0f5;
}

.nav > li > a {
    padding: 10px 15px;
    &:hover {
        text-decoration: none;
        background-color: #eee;
    }
}

label {
    font-weight: 700;
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    line-break: auto;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    font-size: 12px;
    filter: alpha(opacity=0);
    opacity: 0;
}

.tooltip.in {
    filter: alpha(opacity=90);
    opacity: 0.9;
}

.tooltip.top {
    padding: 5px 0;
    margin-top: -3px;
}

.tooltip.right {
    padding: 0 5px;
    margin-left: 3px;
}

.tooltip.bottom {
    padding: 5px 0;
    margin-top: 3px;
}

.tooltip.left {
    padding: 0 5px;
    margin-left: -3px;
}

.tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
}

.tooltip.top-left .tooltip-arrow {
    right: 5px;
    bottom: 0;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
}

.tooltip.top-right .tooltip-arrow {
    bottom: 0;
    left: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
}

.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000;
}

.tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #000;
}

.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
}

.tooltip.bottom-left .tooltip-arrow {
    top: 0;
    right: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
}

.tooltip.bottom-right .tooltip-arrow {
    top: 0;
    left: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
}

.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 4px;
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

// add the code bellow
@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
        background: transparent;
        width: 0px;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    /* Utility class for hidden as 'hidden' is also used as a class in bootstrap css with !important which overrides when used with media queries ie sm:hidden md:bock */
    .is-hidden {
        display: none;
    }
}

app-organisation-menu {
    .p-button {
        font-size: 14px !important;
    }
}

app-record-map .mapboxgl-ctrl-top-left {
    display: flex;
}
app-record-map app-map-menu-control {
    order: 1;
}
