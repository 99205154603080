@import "../theme/variables";

project-sidebar {
    flex-basis: $project-sidebar-width;
    transition: flex-basis 250ms;
    flex-grow: 0;
    flex-shrink: 0;
    width: $project-sidebar-width;
    overflow: hidden;

    // &:hover {
    //     flex-basis: 180px;
    //     width: 180px;
    // }

    &.open {
        flex-basis: $project-sidebar-width-open;
        width: $project-sidebar-width-open;
    }
}

.project-content-wrapper {
    overflow: hidden;
    flex-direction: row;
    background-color: #f4f7fc;
    flex-grow: 1;
    display: flex;

    .content-view {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    div[ui-view] {
        overflow-y: auto;
        position: relative;
        width: 100%;
        height: 100%;
    }

    div[ui-view] {
        overflow-y: auto;
        position: relative;
        width: 100%;
        // height: 100%;
        flex-grow: 1;
    }

    .project-loading,
    .project-error {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .project-loading {
        z-index: 9999;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.7);
    }

    .project-content.loading {
        opacity: 0.2;
    }
}

.project-content-padding {
    padding: 1rem;
    @screen sm {
        padding: $content-padding;
    }
    max-width: 1280px;
    margin: 0 auto;
}
