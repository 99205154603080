project-records-table {
    .tabulator {
        @apply text-xs border-r-0;
    }

    .tabulator-row:hover .record-id i {
        @apply visible;
    }

    .tabulator-placeholder {
        @apply max-w-full;
    }

    .tabulator-footer .tabulator-footer-contents {
        padding-inline: 64px !important;
    }

    .record-id {
        span {
            @apply mr-2;
        }
        i {
            @apply invisible;
        }
    }

    .project-records-table-id {
        width: 100%;
        display: block;
        color: #333333;
        text-align: center;

        i {
            visibility: hidden;
            float: right;
            color: #333333;
            line-height: 15px;
            font-size: 10px;
        }

        &:hover i {
            visibility: visible;
        }
    }

    .project-records-details-pane {
        background-color: #fafafa;
        padding: 25px;
        box-shadow:
            inset 0px 15px 8px -15px rgba(0, 0, 0, 0.5),
            inset 0px -12px 5px -15px rgba(0, 0, 0, 0.2);
    }

    .tabulator-frozen {
        border-right: none !important;
        outline: none !important;
    }

    app-image-modal-viewer {
        min-height: 40px;
    }

    .project-records-table-form-color {
        @apply w-3 h-3 bg-primary rounded-full inline-block mr-2 shadow flex-shrink-0;
    }
}
