/// Border of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBorder: 0 none;

/// Background of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBg: $panelContentBg;

/// Box shadow of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContainerShadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);

/// Background of a dialog header
/// @group overlay
$dialogHeaderBg: $primaryColor;

/// Border of a dialog header
/// @group overlay
$dialogHeaderBorder: none;

/// Text color of a dialog header
/// @group overlay
$dialogHeaderTextColor: #fff;

/// Font weight of a dialog header
/// @group overlay
$dialogHeaderFontWeight: 600;

/// Font size of a dialog header
/// @group overlay
$dialogHeaderFontSize: 18px;

/// Padding of a dialog header
/// @group overlay
$dialogHeaderPadding: 20px;

/// Padding of a dialog content
/// @group overlay
$dialogContentPadding: 20px;

/// Border of a dialog footer
/// @group overlay
$dialogFooterBorder: none;

/// Padding of a dialog footer
/// @group overlay
$dialogFooterPadding: 10px 12px;

/// Background of a tooltip
/// @group overlay
$tooltipBg: #3e4247;

/// Text color of a tooltip
/// @group overlay
$tooltipTextColor: #ffffff;

/// Padding of a tooltip
/// @group overlay
$tooltipPadding: 20px;
