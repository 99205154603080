.tooltip {
    &.in {
        opacity: 1;
    }

    &-inner {
        @apply text-grey-5 bg-grey-80 p-2 rounded-lg text-xs font-bold;
    }

    &.top .tooltip-arrow {
        border-top-color: theme('colors.grey.80');
    }

    &.right .tooltip-arrow {
        border-right-color: theme('colors.grey.80');
    }

    &.left .tooltip-arrow {
        border-left-color: theme('colors.grey.80');
    }
}