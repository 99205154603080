project-records-toolbar {
    @apply bg-white;

    flex: 0 0 40px;

    .fa-bars {
        transform: rotate(90deg);
    }

    .toolbar--forms {
        @apply w-80 mx-0;

        .coreo-button {
            @apply mx-0;
        }
    }

    md-input-container {
        margin: 0;
    }

    md-toolbar {
        @apply h-10;
        min-height: 40px;
    }

    .md-toolbar-tools {
        min-height: 40px;
        @apply h-10 flex;
        border-bottom: 1px solid #2680FF;
    }

    .project-records-toolbar--menu {
        display: flex;

        md-menu {
            flex: 1 1 33%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.project-records-toolbar--column-list {
    padding: 0 !important;
    max-height: 60vh !important;
    overflow: hidden;

    p {
        margin: 0;
    }

    md-list {
        overflow-y: auto;
    }

    md-list-item {
        margin: 0;
        padding: 0 !important;
        border: 0;
        // max-height: 32px;
    }

    md-input-container {
        margin: 0;
        padding: 8px 12px;
        max-height: 54px;
    }
}

.project-records-toolbar--column-buttons {
    padding: 0 12px;

    .coreo-button {
        font-size: 10px;
        line-height: 20px;
        margin: 0 8px 0 0;
    }
}

md-menu-content .header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 16px 8px;
    border-bottom: 1px solid #888;
    font-size: 14px;

    p {
        flex: 1;
        margin: 0;
        font-weight: 700;
    }

    label {
        color: #888;
        font-weight: 500;
        margin-right: 10px;
        cursor: pointer;
    }
}