@import "variables";

md-list {
    padding: 0;
}

md-list-item {
    margin: $item-margin;
    padding: $item-padding !important;
    border: $item-border;
    border-radius: $item-border-radius;

    .md-button {
        line-height: 22px;
    }

    .app-icon-avatar {
        width: 70px;
        height: 70px;
        margin-right: 15px;
        border-radius: $app-icon-border-radius;
    }

    i {
        margin-left: auto;
        color: map-get($colors, primary);
    }
}

md-list-item:last-child {
    margin: 0px;
}

md-list-item._md-button-wrap>div.md-button {
    padding: 0 !important;
}

md-list-item:hover {
    background-color: map-get($colors, background);
}

.item {
    padding: $item-padding;
    border-radius: $item-border-radius;
    cursor: pointer;

    .item-actions {
        margin-left: auto;
    }
}

.item:hover {
    background-color: map-get($colors, background);
}

.project-dashboard-app-item {
    @extend .item;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 84px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;

    img {
        margin-bottom: 10px;
        border-radius: $app-icon-border-radius;
    }
}

.project-config-item {
    @extend .item;

    @apply w-28 font-bold text-xs text-center cursor-pointer;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;

        i {
            font-size: 30px;
        }
    }
}