mapbox-style-editor {
    input {
        width: 100%;
        border-radius: 4px;
        // background: #333f46;
        // color: white;
        height: 28px;
    }

    pre {
        font-size: 9px;
        // background: #333f46;
        // color: white;
        border-radius: 4px;
    }

    .mapbox-prop {
        cursor: pointer;
        margin-top: 4px;
        border-radius: 4px;
        padding: 2px 6px;

        &:hover {
            background: #333f46;
        }

        &.active {
            background: rgb(96, 125, 139);
            color: white;
        }

        label {
            width: 100%;
            line-height: 16px !important;
            cursor: pointer;
            margin: 0;
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        a {
            visibility: hidden;
            cursor: pointer;
        }

        &:hover {
            a {
                visibility: visible;
            }
        }

        p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: 0;
            min-height: 20px;
            font-size: 12px;
        }

    }

    .mapbox-prop-checkbox {
        &>div {
            justify-content: flex-end;
        }

        input {
            width: 20px;
        }
    }

    .prop-formula {
        display: block;
        overflow: hidden;
        width: 340px;
        max-width: 340px;
        border: 1px solid green;
        border-radius: 4px;


        &.invalid {
            border: 1px solid red;
        }
    }

    .mapbox-style-editor__props-list {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    .mapbox-prop-editor {
        padding: 2px 12px;
    }
}