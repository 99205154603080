@import '../theme/variables';

.unauthenticated-content-wrapper {

    @apply max-h-screen;

    // background: map-get($colors, primary) !important;
    span.lead {
        color: #4AE8FF;
        font-size: 1.75rem;
    }

    .unauthenticated--sidebar {
        background: linear-gradient(345.81deg, rgba(74, 227, 255, 0.39) -10.39%, rgba(0, 105, 223, 0.4) 89.86%), #0069DF;

        h1 {
            font-size: 1.7rem;
        }

        @screen md {
            width: 376px;
        }
    }

    .spinner {
        border-top-color: #fff;
    }
}