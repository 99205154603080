@import '../theme/variables';

project-media-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $card-content-padding;

    .project-media-item-img {
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .project-media-item-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .project-media-item-content {
        padding-top: $card-content-padding;
        flex-direction: column;
        text-align: center;
        overflow: hidden;

        a {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
        }
    }

    .project-media-item-container-audio {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        audio {
            width: 100%;
        }
    }
}