.list-item {
    // border-top: 1px solid #f4f4f4;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;

    &:first-of-type {
        border-top: none;
    }
}

.list-item-value,
.list-item-label {
    padding: 8px;
    flex: 1 1 100%;
    max-height: 100%;
    box-sizing: border-box;
}

.list-item-label {
    max-width: 40%;
}

.list-item-value {
    max-width: 60%;
}

.list.align-right {
    .list-item-value {
        text-align: right;
    }
}

.list {
    overflow: hidden;
}

.list.compact {

    .list-item-value,
    .list-item-label {
        padding: 4px;
    }
}

.list.stacked {
    .list-item {
        flex-direction: column;
        margin-bottom: 4px;
    }

    .list-item-value,
    .list-item-label {
        max-width: 100%;
    }

    .list-item-label {
        font-size: 11px;
        padding: 0 8px;
    }
}