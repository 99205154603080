.color-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.variant {
    padding-bottom: 5px;
}

.color-preview {
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 25px;
    height: 25px;
    margin-right: 8px;
}

.color-label {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.picker-container {
    width: 175px;
    align-items: center;
    display: flex;
    i {
        cursor: pointer;
    }
}

.picker-container-inner {
    width: 100%;
}

.dropdown-icon {
    padding-left: 8px;
}

.theme-container {
    width: 30px;
    height: 30px;

    .primary {
        width: 100%;
        height: 100%;
    }
    .secondary {
        width: 100%;
        height: 100%;
        clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    }
    .tertiary {
        width: 100%;
        height: 100%;
        clip-path: polygon(100% 66%, 66% 100%, 100% 100%);
    }
}

.theme-option {
    display: flex;
    align-items: center;
}

.row-margin-override {
    margin-right: -15px;
    margin-left: -15px;
}