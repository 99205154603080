@import '../theme/variables';

.intermediarypage-content-wrapper {
    @apply max-h-screen;

    // @screen sm {
    //     @apply grid;
    //     grid-template-columns: 3fr 2fr;
    //     grid-template-rows: 90px 1fr;
    // }


    // background: map-get($colors, primary) !important;
    span.lead {
        color: #4AE8FF;
        font-size: 1.75rem;
    }

    .intermediarypage--sidebar {
        background: linear-gradient(345.81deg, rgba(74, 227, 255, 0.39) -10.39%, rgba(0, 105, 223, 0.4) 89.86%), #0069DF;
        width: 450;
        display: none;
        
        h1 {
            font-size: 1.7rem;
        }

        @screen md {
            display:block;
            width: 376px;
        }
    }

    .intermediarypage--header {
        background: linear-gradient(0deg, rgba(74, 227, 255, 0.39) -10.39%, rgba(0, 105, 223, 0.4) 89.86%), #0069DF;
        width: 100%;
        @screen md {
            display:none
        }
    }

    .spinner {
        border-top-color: #0069DF;
    }
}