.account-verification {
    img {
        border-radius: 4px;
        max-width: 240px;
        margin: 0 auto;
    }

    .login-box-body {
        border-radius: 4px;
    }
}