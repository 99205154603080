@import '../../theme/variables';

.media-manager {

    .single-media-item {
        align-content: center;
        display: flex !important;
        justify-content: center;
    }

    .media-manager-container {
        display: flex;
        flex-direction: row;
        height: 100%;
    }

    .media-manager-sidebar {
        width: 200px;
        flex: 0 200px;
    }

    .media-manager-tag-list {
        padding-left: 0px;

        .media-manager-tag-list-item {
            display: flex;
            align-items: center;
            padding: $button-padding;
            height: 50px;
            color: map-get($colors, medium);
            font-size: $button-font-size;
            font-weight: $button-font-weight;
            text-transform: uppercase;

            i {
                margin-right: 10px
            }

            .md-button {
                margin-left: auto;
            }
        }

        .media-manager-tag-list-item:hover {
            background-color: map-get($colors, background);
        }

        .media-manager-tag-list-item.active {
            color: white;
            background-color: map-get($colors, primary);

            .md-button {
                color: white;
            }
        }

        .media-manager-tag-list-item.active:hover {
            background-color: map-get($colors, primary);
        }
    }

    .media-manager-main {
        flex: 1;
    }


    md-menu-content {
        padding: 0px;
    }

    .media-manager-list {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
        grid-gap: calc(#{$card-margin} * 2);
        align-items: stretch;
        
        margin:40px;
    
        li {
            position: relative;
            width: 175px;
            height: 200px;
            background-color: white;
            transition: transform .1s ease-in, -webkit-transform .1s ease-in;
            cursor: pointer;

            md-progress-circular {
                margin: auto;

                svg {
                    stroke: map-get($colors, dark);
                }
            }
    
            // i {
            //     visibility: hidden;
            //     position: absolute;
            //     top: 2px;
            //     left: 0px;
            //     font-size: 18px;
            // }
    
            // &:hover {
            //     //transform: scale(1.05); // border: 1px solid #233680;
            //     box-shadow: $index-card-hover-box-shadow;
    
            //     i {
            //         visibility: visible;
            //     }
            // }
    
            // &.item-selected {
            //     box-shadow: $index-card-hover-box-shadow, 0 0 0 2px map-get($colors, primary-highlight);
    
            //     i {
            //         color: green;
            //     }
            // }
        }

        .media-manager-item-container {
            height: 175px;
            width: 175px;
        }

        .media-manager-add-item-container {
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='2' stroke-dasharray='12.5' stroke-dashoffset='32' stroke-linecap='round'/%3e%3c/svg%3e");
            height: 175px;
            width: 175px;
            display: flex;
            align-items: center;
            justify-content: center;

            i { 
                font-size: 36px;
            }
        }

        .media-manager-add-item-text {
            padding-top: 5px;
            flex-direction: column;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
        }
    
        .media-manager-item-img {
            height: 100%;
        }

        .media-manager-list-loading-item {
            position: relative;
            width: 175px;
            height: 175px;
            display: flex;
            align-items: center;
        }
    
        &.is-selecting {
            li .media-manager-item-toolbar {
                visibility: hidden;
            }
        }
    } 
    .media-manager-upload {
        display: none;
    }
}

.modal-fullscreen {
    .modal-dialog {
        min-width: 75%; 
    }
}

.media-manager-item-edit-body {
    display: flex;
    padding: 0px;

    width: 100%;
    height: 100%;

    .media-content {
        flex: 1;
        padding: $padding-1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: map-get($colors, background);
    }

    .media-details {
        width: 300px;
        display: flex;
        align-items: center;
        padding: $padding-1;
        border-left: 1px solid map-get($colors, lighter);

        ul { 
            width: 100%;
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                padding: 20px 0;
                border-bottom: 1px solid map-get($colors, lighter);

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        textarea {
            display: block;
            width: 100%;
            margin-top: 8px;
        }
    }
}

.single-media-item {
    align-content: center;
    display: flex;
    justify-content: center;
}