@import "variables";

.display-1 {
    font-size: 32px;
    font-weight: bold;
}

.display-2 {
    font-size: 24px;
    font-weight: bold;
}

.display-3 {
    font-weight: bold !important;
    font-size: 18px !important;
}

.display-4 {
    font-weight: bold;
    font-size: 16px;
}

.display-5 {
    font-weight: bold;
    font-size: 14px;
}

.display-6 {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: map-get($colors, medium);
}

.body-1 {
    font-weight: normal;
    font-size: 14px;
}

.body-2 {
    font-weight: normal;
    font-size: 12px;
}

.body-3 {
    font-weight: normal;
    font-size: 10px;
}