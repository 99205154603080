@import "variables";

md-select {
    height: $input-min-height;
    margin: 0px;
    padding: 0 8px 0 15px;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;

    md-select-value {
        border-bottom: none !important;
    }
}

md-autocomplete {
    height: $input-min-height;
    margin: 0px;
    padding: 0 8px 0 15px;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;

    md-autocomplete-wrap {
        box-shadow: none !important;
    }
}

md-slider {
    .md-track-fill {
        background-color: map-get($colors, primary);
    }

    .md-thumb {
        background-color: map-get($colors, primary);
    }

    .md-thumb::after {
        display: none;
    }
}

label {
    color: map-get($colors, dark);
}

input {
    min-height: $input-min-height;
}

input:focus,
textarea:focus {
    outline: none !important;
    border-color: $input-border-color-focus !important;
}

.md-select-menu-container {
    md-select-menu {
        padding: 0px;
    }

    md-content {
        padding: 8px;
    }

    md-option {
        padding: $item-padding;
        border-radius: $item-border-radius;
    }

    md-option:hover {
        background-color: map-get($colors, background);
    }
}

.form-group {
    .form-control-feedback {
        color: map-get($colors, medium);
        top: calc(25px + ((#{$input-min-height} - 34px) / 2)) !important;
    }
}

.checkbox label,
.radio label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    input[type="checkbox"],
    input[type="radio"] {
        position: static;
        min-height: 20px;
        margin-right: 12px;
    }
}

md-checkbox.md-checked .md-icon,
md-option.md-checkbox-enabled[selected] .md-icon {
    background-color: map-get($colors, primary);
    color: white;
}

// New Forms
.coreo-form-group {
    margin-bottom: 1rem;

    label,
    .coreo-form-group__label {
        @apply text-grey-70;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 5px;
        max-width: 100%;
    }

    input[type="text"],
    input[type="url"],
    input[type="search"],
    input[type="email"],
    input[type="password"],
    input[type="number"],
    textarea {
        @apply bg-white block border border-grey-20 text-sm font-normal w-full text-grey-100;
        border-radius: 4px;
        line-height: 16px;
        padding-left: 12px;

        &:focus {
            box-shadow:
                0px 0px 0px 1.25px #0069df,
                0px 0px 0px 6px rgba(0, 117, 248, 0.1);
        }
    }

    input[type="search"] {
        @apply cursor-pointer;
    }

    md-select {
        @apply bg-white block border border-grey-20 text-sm font-normal w-full text-grey-100 h-10 leading-4 px-2;

        md-select-value {
            @apply h-full p-0;
        }
    }

    md-autocomplete {
        @apply border border-grey-20 px-0 box-content;

        input[type="text"] {
            @apply px-2;
            box-shadow: none;

            &:focus {
                box-shadow: none;
            }
        }
    }

    md-datepicker {
        @apply font-normal text-grey-100;
    }

    textarea {
        padding-top: 12px;
    }

    select {
        display: block;
        background: #ffffff;
        width: 100%;
        border: 0;
        min-height: 40px;
        box-shadow: 0px 0px 0px 1px #d9dbde;
        border-radius: 4px;
        padding-left: 12px;
        color: #0e0f11;
        font-size: 14px;
        line-height: 16px;
        cursor: pointer;
    }

    &.checkbox {
        display: flex;

        input {
            position: static;
            margin-left: 0;
        }

        label {
            @apply text-grey-80;
            @apply text-sm;
        }
    }

    small {
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        color: #878f97;
        display: block;
        margin: 4px 0;
    }

    &.invalid {
        input[type="text"],
        input[type="search"],
        input[type="url"],
        input[type="email"],
        input[type="password"],
        input[type="number"],
        textarea {
            box-shadow:
                0px 0px 0px 1.25px #fa4d56,
                0px 0px 0px 6px rgba(250, 77, 86, 0.1);
        }

        small {
            color: #fc4b54;
        }

        &.checkbox label {
            color: #fc4b54;
        }
    }

    &.error {
        color: #fc4b54;
        font-weight: bold;
    }

    md-select,
    md-autocomplete,
    md-datpicker,
    textarea,
    select,
    input {
        &[disabled] {
            @apply opacity-30;
        }
    }
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px #00000013;
    transition:
        border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
}
