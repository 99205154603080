@import "../theme/variables";

.project-grid-container {
    padding: $content-padding;
    overflow-y: auto;
}

.project-search {
    max-width: 75vw;
    margin: 0 auto 16px auto;
    background: white;
    display: flex;
    align-items: center;
    font-size: 1rem;
    padding: 0 32px;
    box-shadow: 0 3px 5px 0 rgba(41, 49, 52, 0.1);
    border-radius: 5px;

    input {
        border: 0;
        padding: 16px 32px;
        width: 100%;
    }
}

.project-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; // IE11 Fix
    justify-content: center;
    width: 100%;
}

[uib-dropdown].open > .dropdown-menu {
    opacity: 1;
    visibility: visible;
    margin-top: 0px;
}

[uib-dropdown] .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    margin-top: -5px;
    transition:
        opacity 200ms ease,
        margin-top 200ms ease,
        visibility 200ms ease;
}

.project-template-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.projects-empty-header {
    width: 100%;
    text-align: center;
    margin: 32px 0;
}

.projects-empty-apps {
    background-color: #dae2eb;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 0 32px;
    padding: 12px 32px 48px 32px;
    text-align: center;

    p:first-child {
        font-style: italic;
        margin-bottom: 24px;
    }
}

.projects-empty-apps-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.projects-empty-apps-grid-app {
    width: 120px;
    height: 120px;
    border: 2px solid #fff;
    border-radius: 8px;

    img {
        width: 100%;
        height: 100%;
    }

    p {
        color: #333;
        margin-top: 4px;
    }
}

.projects-empty-divider {
    margin: 20px 0;
    text-align: center;

    span {
        display: inline-block;
        position: relative;
        border: 1px solid #ccc;
        border-radius: 50%;
        padding: 12px;
        background-color: #ccc;
        color: black;
        font-weight: bold;
    }
}

.projects-empty-create {
    text-align: center;
    cursor: pointer;

    .projects-empty-apps-grid-app {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 64px;
        color: white;
        background-color: #dae2eb;
    }
}

.footer-legal {
    padding: 6px 8px 0 8px;
    border-top: 1px solid rgba(218, 226, 235, 0.41);
    margin: 12px 10px 0 10px;

    ul {
        padding: 0;
        display: flex;
        justify-content: space-evenly;
        font-size: 11px;
        color: #b8c7ce;
        list-style: none;
    }
}

.footer-company {
    color: #b8c7ce;
    font-size: 11px;
    text-align: center;
    margin-bottom: 6px;

    a,
    a:hover {
        color: #b8c7ce;
    }
}

.display-flex {
    display: flex;
}

project-widget {
    md-card {
        cursor: pointer;
    }

    md-card-footer {
        height: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .index-card {
        height: 220px;
        display: flex;
        flex-direction: column;
    }
}

.new-project-card {
    i {
        font-size: 52px;
    }
}
