@import "../theme/variables";

.main-content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .content-view {
        overflow-y: auto;
        flex-grow: 1;
    }
}

.main-content-header {
    a {
        // background: white;

        img {
            height: $projectHeaderHeight;
            padding: calc((#{$projectHeaderHeight} - 40px) / 2);
            padding-left: 1rem;
        }
    }
}

.app-footer {
    @apply border-t border-grey-15 flex flex-shrink-0 text-xs items-center justify-center;
    flex-basis: 40px;
    line-height: 40px;

    img {
        @apply h-3 w-auto inline-block px-1;
    }
}

.small-device {
    @apply md:hidden;
}

.non-small-device {
    @apply hidden md:block;
}
