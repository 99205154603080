/* Colours */
$colors: (primary: #0069DF,
    primary-highlight: #4c72ff,
    light-blue: #21d4fd,
    green: #66e16a,
    orange: #ffa200,
    red: #ec454e,
    purple: #b721ff,
    dark: #06222d,
    sidebar: #273123,
    medium: #8093a9,
    light: #beccdd,
    lighter: #e6ecf3,
    background: #f4f7fc,
    facebook: #3C5A99,
    google: #DB4437);


/* Content Variables */

$content-padding: 48px;
$padding-1: 15px;

/* Sidebar Variables */

$project-sidebar-width: 60px;
$project-sidebar-width-open: 220px;
$projectHeaderHeight: $project-sidebar-width;


/* Modal Variables */

$modal-padding: 15px;
$modal-border-radius: 5px;
$modal-background: white;
$modal-box-shadow: 0 4px 15px 0 rgba(41, 49, 52, 0.50);

$modal-toolbar-min-height: 65px;
$modal-toolbar-background-color: map-get($colors, primary);
$modal-toolbar-color: white;
$modal-toolbar-padding: 15px;

$modal-footer-padding: 10px 15px;


/* Card Variables */

$card-margin: 7.5px;
$card-border-radius: 5px;
$card-background-color: white;
$card-box-shadow: 0 3px 5px 0 rgba(41, 49, 52, 0.10);
$card-content-padding: 15px;
$card-header-text-padding: 5px;

$index-card-width: 175px;
$index-card-icon-size: 32px;
$index-card-title-font-size: 16px;
$index-card-description-font-size: 12px;
$index-card-box-shadow: $card-box-shadow;
$index-card-hover-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);

$card-selected-border-width: 2px;


/* Form Variables */

$input-min-height: 40px;
$input-border-radius: 5px;
$input-border-color: map-get($colors, light);
$input-border-color-focus: map-get($colors, primary-highlight);


/* List/Items Variables */

$item-margin: 0 0 10px 0;
$item-padding: 5px 10px;
$item-border: 1px solid map-get($colors, background);
$item-border-radius: 5px;


/* Button Variables */

$button-height: 44px;
$button-margin: 5px;
$button-padding: 0 15px;
$button-border-radius: 6px;
$button-box-shadow: 0 4px 8px 0 rgba(6, 34, 45, 0.25);
$button-font-size: 14px;
$button-font-weight: bold;

$button-small-height: 32px;
$button-small-font-size: 12px;

$button-mini-height: 20px;
$button-mini-font-size: 10px;

$icon-button-height: 56px;
$icon-button-small-height: 40px;


/* Image Variables */

$app-icon-border-radius: 15px;


/* Table Variables */

$table-header-cell-padding: 10px 15px;
$table-row-cell-padding: 7.5px 15px;
$table-row-even-background-color: map-get($colors, background);
$table-cell-hover-background-color: theme('colors.grey.15');
$table-cell-border: 1px solid theme('colors.grey.15');

$table-pagination-button-padding: 0 10px;
$table-pagination-button-border-radius: 10px;