collection-select {
    @apply relative block;

    input[type="search"],
    input[type="text"] {
        @apply box-border w-full;
    }

    input:focus {
        border: 2px solid blue;
    }
    md-chips-wrap.md-chips {
        padding: 0;
        // min-height: 40px;
        .md-chip-input-container {
            margin: 0;
        }
        md-autocomplete,
        md-autocomplete-wrap {
            height: 100% !important;
        }
        md-autocomplete-wrap {
            padding-left: 12px;
        }
    }

    datalist {
        option {
            @apply text-grey-100;
            &:hover{
                @apply bg-grey-10;
            }
        }
        .highlight {
            @apply font-semibold text-primary;
        }
    }
}

.collection-select-container md-content {
    padding: 0 !important;
}
