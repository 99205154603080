.searchable-list .searchable-list-searchbox {
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    padding: 0;
}

.searchable-list .searchable-list-header {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
    padding-left: 16px;
    height: 48px;
    cursor: pointer;
    position: relative;
    display: flex;
    width: auto;
}

.searchable-list md-content._md {
    max-height: 240px;
}

.searchable-list md-input-container {
    min-width: 112px;
}