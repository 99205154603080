.admin-boundaries-content {
    position: relative;
    height: 100%;
}

#boundary_map {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.admin-boundaries-list {
    position: absolute;
    right: 20px;
    left: 20px;
    z-index: 999;
    background: white;
    border-radius: 4px;
    height: auto;
    width: 200px;
}