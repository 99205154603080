.ce-paragraph--right {
    text-align: right;
}

.ce-paragraph--center {
    text-align: center;
}

.ce-paragraph--left {
    text-align: left;
}

.ce-tune-alignment--right {
    text-align: right;
}
.ce-tune-alignment--center {
    text-align: center;
}
.ce-tune-alignment--left {
    text-align: left;
}

.image-tool__caption {
    display: none;
}

.ce-delimiter:before {
    line-height: initial;
}

.image-tool__image-picture {
    display: inline !important;
}

.toolbox-icon {
    font-size: 18px;
}

.ce-header {
    padding: 0.6em 0 3px;
    margin: 0;
    line-height: 1.25em;
    outline: none;
}

.ce-header p,
.ce-header div {
    padding: 0 !important;
    margin: 0 !important;
}

/**
   * Styles for Plugin icon in Toolbar
   */
.ce-header__icon {
}

.ce-header[contentEditable="true"][data-placeholder]::before {
    position: absolute;
    content: attr(data-placeholder);
    color: #707684;
    font-weight: normal;
    display: none;
    cursor: text;
}

.ce-header[contentEditable="true"][data-placeholder]:empty::before {
    display: block;
}

.ce-header[contentEditable="true"][data-placeholder]:empty:focus::before {
    display: none;
}
