mapbox-prop-editor {
    .mapbox-prop-editor__toolbar {
        md-icon {
            // color: white;
        }

        .md-button {
            display: flex;
            justify-content: center;
            align-items: center;

            // &.active {
            //     background: #b8c7ce;

            //     md-icon {
            //         color: #333;
            //     }
            // }
        }

    }

    .CodeMirror {
        height: auto;
        font-size: 12px;
    }

    .mapbox-json-from-attributes {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        label {
            margin-bottom: 0;
            line-height: 26px;
            margin-right: 4px;
        }

        span {
            margin: 2px 2px;
            border-radius: 2px;
            background: rgba(184, 199, 206, 0.1);
            font-size: 12px;
            padding: 4px;
        }
    }

    // .mapbox-prop-editor__json,
    // .mapbox-prop-editor__value {
    //     visibility: hidden;

    //     &.active {
    //         visibility: visible;
    //         // display: block;
    //     }
    // }
}