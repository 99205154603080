map-data-layer-editor {
    .map-data-layer-editor__preview--point {
        @apply rounded-full w-8 h-8;
    }

    input[type="color"] {
        @apply appearance-none border-none w-8 h-8 rounded-full overflow-hidden shadow cursor-pointer;
        -webkit-appearance: none;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
        @apply p-0;
    }
    input[type="color"]::-webkit-color-swatch {
        @apply border-none;
    }

    .coreo-form-group:has(input[type="range"]) {
        label {
            @apply mb-0;
        }
    }


    .coreo-form-group:has(input[type="color"]) {
        @apply items-center justify-between flex;
    }
    .coreo-form-group:has(md-checkbox) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
