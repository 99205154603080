.password-update {
    img {
        border-radius: 4px;
    }
    h3 {
        color: white;
    }
    .login-box-body {
        border-radius: 4px;
    }
}