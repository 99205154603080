@import "../../theme/variables";

app-breadcrumbs {
    @apply flex items-center;

    & + div.container-fluid {
        overflow-y: auto;
        margin-left: 0;
        margin-right: 0;
        padding: 15px;
    }

    a,
    i {
        @apply text-grey-50 transition-colors;
    }

    .breadcrumb-seperator {
        @apply mx-2 text-grey-20;
    }

    i.step-icon {
        @apply cursor-pointer text-xs;
    }

    span {
        @apply text-grey-50 inline-flex items-center h-full gap-3;
        // border-bottom-width: 3px;
        // border-bottom-color: transparent;

        &:hover {
            a,
            i.step-icon {
                @apply text-primary;
            }
        }

        &:last-of-type {
            @apply pointer-events-none;

            a,
            i {
                @apply text-grey-100;
            }

            pointer-events: none;
        }
    }
}

// #app-help {
//     @apply ml-auto mr-2 text-primary cursor-pointer text-base w-4 h-4 leading-4 hidden;
//     @screen sm {
//         @apply block;
//     }
// }
