project-map-base-selector {
    visibility: hidden;

    &.mapboxgl-ctrl {
        visibility: visible;
    }

    md-select {
        width: 200px;
        background: rgba(255, 255, 255, 0.9);
        cursor: default;
        font-size: 14px;
        margin: 0;
        border-radius: 4px;
        padding: 0 2px;
        margin-bottom: 4px;
    }
}