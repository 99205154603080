$input-height: 28px;

.project-map-editor {
    @apply w-full h-full flex;

    .coreo-form-group {
        // @apply mb-2;
        input {
            min-height: $input-height;
        }
        md-select {
            height: $input-height;
        }
    }

    &__layer {
        @apply rounded shadow bg-white p-2 text-sm cursor-pointer font-bold my-1 flex items-start flex-col;

        .fas {
            @apply ml-auto;
        }

        span {
            @apply pr-3 text-xs;
        }

        p {
            @apply text-xs text-grey-50;
        }

        &:hover {
            @apply bg-grey-50 text-white;
            p {
                @apply text-white;
            }
        }
        &.active {
            @apply bg-primary text-white;
            p {
                @apply text-white;
            }
        }
    }

    &__layer.visible {
        .fa-eye {
            @apply invisible;
        }

        &:hover {
            .fa-eye {
                visibility: visible;
            }
        }
    }

    &__toolbar {
        @apply flex flex-col;

        button {
            @apply focus:outline-none rounded opacity-70 m-0;
            min-width: 64px;

            &:hover {
                @apply opacity-100;
            }

            &[disabled] {
                @apply opacity-30;
            }

            md-icon {
                @apply flex items-center justify-center;
            }
        }

        label {
            @apply m-0;
        }

        .coreo-form-group {
            @apply mb-0;
        }
    }

    form .coreo-form-group {
        @apply mb-0;
    }

    form button.md-button {
        @apply mx-0;
    }

    button.coreo-button {
        @apply mx-0 mt-auto;
    }

    section {
        @apply border-t border-grey-50 pt-2;

        .coreo-form-group {
            @apply pl-4;
        }
    }
}
