.organisation-settings {
    @apply p-12 h-full mx-auto max-w-7xl;

    md-card-content {
        flex-grow: 1;
    }

    &--image {
        height: 164px;
        width: 164px;
        border-radius: 100%;
        box-shadow: 0px 4.09357px 7.16374px rgba(14, 15, 17, 0.08), 0px 2.04678px 24.5614px rgba(14, 15, 17, 0.1);
        margin-bottom: 16px;
    }

    &--seats {
        margin-right: 1.5rem;
        color: #555C63;
        font-size: 12px;
    }

}