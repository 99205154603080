project-record-state {
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: white;

    md-select {
        border: 0;
        padding: 0;
        flex: 1;
    }

    md-icon {
        margin: 0 4px;
        text-align: center;
    }

    .md-select-value {
        @apply m-0;

        .md-select-icon {
            text-align: left;
        }
    }
}
