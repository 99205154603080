tutorial {
    @apply absolute top-0 left-0 right-0 bottom-0 hidden;
    z-index: 9999998;

    @media only screen and (min-width: 768px) {
        &.active {
            display: block !important;
        }
    }

    .tutorial--arrow,
    .tutorial--arrow::before {
        position: absolute;
        width: 8px;
        height: 8px;
        background: inherit;
    }

    .tutorial--arrow {
        visibility: hidden;
    }

    .tutorial--arrow::before {
        visibility: visible;
        content: '';
        transform: rotate(45deg);
    }

    .tutorial--coachmark {
        @apply absolute p-4 rounded-lg top-0 left-0 max-w-xl bg-grey-80 transition-transform;
        filter: drop-shadow(0px 4px 14px rgba(14, 15, 17, 0.15));

        min-width: 172px;

        &[data-popper-placement^='top']>.tutorial--arrow {
            bottom: -4px;
        }

        &[data-popper-placement^='bottom']>.tutorial--arrow {
            top: -4px;
        }

        &[data-popper-placement^='left']>.tutorial--arrow {
            right: -4px;
        }

        &[data-popper-placement^='right']>.tutorial--arrow {
            left: -4px;
        }

        &__footer {
            @apply flex;

            .coreo-skip {
                @apply p-0 m-0;
                margin-top: 10px;
                margin-left: -16px;
                left: 0;
                color: white;
                font-weight: bold;
            }

            .coreo-button {
                @apply p-0 float-right;
                max-height: 40px;
                min-width: 100px;
                background-color: theme('colors.primary.lighten-15');
            }

            &--spacer,
            &--next {
                @apply flex-1;
            }

            &--pager {
                @apply flex items-center justify-evenly flex-grow-0;
                flex-basis: 100px;
            }
        }
    }

}

coachmark {
    @apply hidden;

    &.active {
        display: block !important;
    }

    coachmark-title {
        @apply text-white font-semibold text-base;
    }

    coachmark-content {
        @apply text-grey-5 text-sm font-normal py-2;
    }

    coachmark-icon {
        @apply text-grey-20 mr-2;
    }


}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.tutorial--highlighter {
    @media only screen and (max-width: 768px) {
        display: none !important;
    }
    @media only screen and (min-width: 768px) {
        display: block;
    }

    @apply box-content absolute;
    box-shadow: 0 0 0 99999px rgba(14, 15, 17, 0.4);
    opacity: 1;
    z-index: 9999998;
    animation: 0.5s ease-out 0s 1 fadeIn;
    border-radius: 8px;
    filter: drop-shadow(0px 4px 12px rgba(14, 15, 17, 0.4));
}