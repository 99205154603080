.filter-clear {
    @apply absolute right-4 bg-white top-3 cursor-pointer text-grey-50 opacity-0 transition-opacity pointer-events-none;

    &.active {
        @apply opacity-100 pointer-events-auto;
    }
}

.project-records-attribute-filter-geometryquery,
.project-records-attribute-filter-boolean,
.project-records-attribute-filter-media,
.project-records-attribute-filter-select {
    .filter-clear {
        @apply right-10;
    }
}